.main_container {
  position: relative;
  width: 560px;
  left: 50%;
  transform: translate(-50%);
}

.heading {
  position: relative;
  width: 100%;
  height: 250px !important;
  background: url(../../assets/head-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.logo_left {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: -50%;
  left: 50px;
}

.logo_middle {
  position: relative;
  width: 250px;
  height: 230px;
  border-radius: 50%;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
}

.logo_right {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: -50%;
  right: -450px;
}

.logo_none{
  display: none;
}

.menu {
  position: absolute;
  width: 100%;
  display: inline;
  top: 220px;

  background-color: white;
  border-radius: 25px;
}

.menu_header {
  position: relative;
  padding-right: 3%;
  text-align: end;
}

.menu_location {
  position: relative;
  padding-right: 3%;
  text-align: end;
}

.menu_wifi {
  position: relative;
  padding-right: 3%;
  text-align: end;
}

  
  .mySwiper {
    position: relative;
    height: 75px;
  }

  .swiper_options{
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    text-align: center;
  }

.option {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  width: calc(8rem + 0.8vw);
  height: 45px;
  border: 1px solid rgb(79, 28, 161);
  border-radius: 10px;
  align-items: center;
  padding: 1px 1px;
  cursor: pointer;
  margin: 10px;
}

.option_active{
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  width: calc(8.5rem + 0.8vw);
  height: 45px;
  border-radius: 10px;
  align-items: center;
  padding: 1px 1px;
  cursor: pointer;
  margin: 10px;

  
  border: 3px solid rgb(79, 28, 161);
  box-shadow: 0px 0px 2px 2px rgb(161, 112, 240);
}

.option_imgs {
  position: relative;
  display: inline-block;
  align-items: center;
}

.meals {
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
}

.meals_header {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  margin: auto;
  padding-top: 15px ;
  display: flex;
  flex-direction: column;

  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1)) !important;
  color: white;
  border-radius: 50px 50px 0px 0px;
}

.close {
  position: fixed;
  color: white;
  left: 7%;
  top: 10%;
}

.form {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
}

/* Style the search field */
form.form input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 90%;
  background: #f1f1f1;
  justify-self: center;
  border-radius: 15px 0px 0px 15px;
}

/* Style the submit button */
form.form button {
  float: left;
  width: 7%;
  padding: 5px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
  border-radius: 0px 15px 15px 0px;
}

form.form button:hover {
  background: #0b7dda;
}

/* Clear floats */
form.form::after {
  content: "";
  clear: both;
  display: table;
}

.meals img{
  position: relative;
  left: 50%;
  transform: translate(-50%);
}


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
  .main_container{
    width: 100%;
  }
  
}